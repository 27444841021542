<template>
    <div>
        Dashboard
    </div>
</template>

<script>
    export default {
        data () {
            return {

            }
        },
        methods: {

        },
        components: {

        }
    }
</script>

<style>
</style>
